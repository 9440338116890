import React, { useEffect, useRef, useState } from "react";
import "./App.scss";
import Cloud from "./Cloud.tsx";
import { NumberWidget } from "./NumberWidget/NumberWidget.tsx";
import { BarsWidget } from "./BarsWidget/BarsWidget.tsx";

const queryParameters = new URLSearchParams(window.location.search);
const env = queryParameters.get("env") || "production";
const reportID = queryParameters.get("reportID") || "mJ";

let API_BASE_URL = "https://schedule.warmspace.io";

if (env === "staging") {
  API_BASE_URL = "https://staging.warmspace.io";
}

queryParameters.delete("reportID");
queryParameters.delete("env");

const API_URL = `${API_BASE_URL}/api/v1/live-stats/${reportID}?${queryParameters.toString()}`;

export interface WordCloudWord {
  text: string;
  count: number;
}

interface PollingResponse {
  words: WordCloudWord[];
  energyBefore: number;
  energyAfter: number;
  sessions: number;
  people: number;
  countries: string[];
}

const alphabeticalSort = (a: WordCloudWord, b: WordCloudWord) => {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
};

function App() {
  const wordCloudContainer = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [data, setData] = useState<PollingResponse>({
    words: [],
    energyBefore: 0,
    energyAfter: 0,
    sessions: 0,
    people: 0,
    countries: [],
  });

  // Set up polling
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_URL);
        const data = await response.json();
        setData(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Track word cloud container size
  useEffect(() => {
    const func = () => {
      if (wordCloudContainer.current) {
        size.width = wordCloudContainer.current.offsetWidth;
        size.height = wordCloudContainer.current.offsetHeight;

        setSize({ ...size });
      }
    };

    window.addEventListener("resize", func);

    func();

    return () => {
      window.removeEventListener("resize", func);
    };
  }, [wordCloudContainer.current]);

  return (
    <div className="app">
      <div className="header">
        <h1>Live Connections</h1>
        {env === "staging" && API_URL}
      </div>

      <div className="body">
        <div className="wordcloudWrapper">
          <div className="wordcloudInner" ref={wordCloudContainer}>
            <Cloud
              words={data.words.sort(alphabeticalSort)}
              width={Math.max(1,size.width - 20)}
              height={Math.max(1, size.height - 40)}
            />
          </div>
        </div>

        <div className="side-widgets">
          <NumberWidget
            label="People Connected"
            value={data.people || 0}
            key="people"
          />

          <NumberWidget
            label="Countries"
            value={data.countries.length}
            key="countries"
            bgImage={
              <img
                src="map.png"
                alt="A map of the world as a barely visible background element"
              />
            }
          />

          <BarsWidget
            label="Energy Levels"
            before={data.energyBefore && !isNaN(data.energyBefore) ? data.energyBefore : 1}
            after={data.energyAfter && !isNaN(data.energyAfter) ? data.energyAfter : 1}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
