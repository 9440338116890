import React, { useEffect, useState } from "react";
import "./NumberWidget.scss";

export function NumberWidget({
  label,
  value,
  bgImage,
}: {
  label: string;
  value: number;
  bgImage?: JSX.Element;
}) {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className="widget">
      {bgImage}
      <div className="stat" style={{ "--num": currentValue }}></div>
      <span>{label}</span>
    </div>
  );
}
