import React from "react";

export function BarsWidget({
  label,
  before,
  after,
}: {
  label: string;
  before: number;
  after: number;
}) {
  return (
    <div className="widget" style={{ flex: 2.5 }}>
      <div className="energyBars">
        <div id="energyBefore" className="energyBarWrapper">
          <div className="bar">
            <span>{before.toFixed(1)}</span>
            <div
              className="barFill"
              style={{ height: (before / 10) * 100 + "%" }}
            ></div>
          </div>
          <span>Before</span>
        </div>
        <div id="energyAfter" className="energyBarWrapper">
          <div className="bar">
            <span>{after.toFixed(1)}</span>
            <div
              className="barFill"
              style={{ height: (after / 10) * 100 + "%" }}
            ></div>
          </div>
          <span>After</span>
        </div>
      </div>

      <span>{label}</span>
    </div>
  );
}
